'use client';

import axios from 'axios';
import { useSearchParams } from 'next/navigation';
import queryString from 'query-string';
import type { PropsWithChildren } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ENV } from '@shared/config';
import {
  AuthContext,
  getClientToken,
  removeToken,
  setClientNoCache,
  removeClientNoCache,
} from '@shared/libs';

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [isAuth, setIsAuth] = useState<boolean | null>(null);
  const searchParams = useSearchParams();

  const authContext = useMemo(() => ({ isAuth, setIsAuth }), [isAuth, setIsAuth]);

  const checkAuth = useCallback(async () => {
    const token = getClientToken();
    const pm_id = searchParams.get('pm_id');

    try {
      if (token) {
        const user = await axios.get(
          `${ENV.API}/user/?${queryString.stringify(
            { token, pm_id: pm_id || undefined },
            {
              skipEmptyString: true,
            },
          )}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          },
        );

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        if (user?.data?.userID?.toString() === process.env.NEXT_PUBLIC_NO_CACHE_USER_ID) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          setClientNoCache();
        }
        setIsAuth(true);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        removeClientNoCache();
        setIsAuth(false);

        if (pm_id) {
          await axios.get(`${ENV.API}/user/?pm_id=${pm_id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          });
        }
      }
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      removeClientNoCache();
      setIsAuth(false);
      await removeToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    void checkAuth();
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    window.addEventListener('focus', checkAuth);

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      window.removeEventListener('focus', checkAuth);
    };
  }, [checkAuth]);

  return <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>;
};
