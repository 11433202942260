import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"weight\":[\"300\",\"400\",\"500\",\"700\",\"900\"],\"subsets\":[\"latin\",\"cyrillic\"]}],\"variableName\":\"roboto\"}");
;
import(/* webpackMode: "eager" */ "/app/src/app/(site)/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["YandexMetrika"] */ "/app/src/app/(site)/metrika.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/app/src/app/(site)/provider/providers/auth-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CurrencyProvider"] */ "/app/src/app/(site)/provider/providers/currency-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ModalProvider"] */ "/app/src/app/(site)/provider/providers/modal-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReactQueryProvider"] */ "/app/src/app/(site)/provider/providers/react-query-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/green-bg.jpg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/guarantees-bg.png");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/icons/app-store.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/icons/arrow-diagonal-white.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/icons/arrow-diagonal.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/icons/arrow-down.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/icons/chevron-down.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/icons/chevron-up.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/icons/close-dark.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/icons/close.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/icons/dropdown-icon.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/icons/export.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/icons/facebook.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/icons/favorite.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/icons/flag-rus.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/icons/google-play.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/icons/guarantee.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/icons/import.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/icons/like-grey.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/icons/like-red.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/icons/like.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/icons/link.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/icons/logo.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/icons/menu.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/icons/note.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/icons/pause.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/icons/payments-ru.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/icons/pinterest.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/icons/play.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/icons/pplay.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/icons/print.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/icons/radiobutton-filled.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/icons/radiobutton.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/icons/remove.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/icons/search.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/icons/setting.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/icons/shopping-cart.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/icons/star-gold.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/icons/star.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/icons/user.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/icons/view.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/icons/vk.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/icons/youtube.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/icons/zoom.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/notes-list.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/preview.jpg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/assets/subscribe-bg.png");
;
import(/* webpackMode: "eager", webpackExports: ["AuthContext"] */ "/app/src/shared/libs/auth-context/auth-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CurrencyContext"] */ "/app/src/shared/libs/currency-context/currency-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useFavourites"] */ "/app/src/shared/libs/hooks/use-favourites.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["usePagination"] */ "/app/src/shared/libs/hooks/use-pagination.ts");
;
import(/* webpackMode: "eager", webpackExports: ["useUrlParam"] */ "/app/src/shared/libs/hooks/use-url-param.ts");
;
import(/* webpackMode: "eager", webpackExports: ["ModalContext"] */ "/app/src/shared/libs/modal-context/modal-context.ts");
;
import(/* webpackMode: "eager", webpackExports: ["Player"] */ "/app/src/shared/libs/player/player.tsx");
