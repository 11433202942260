'use client';

import type { PropsWithChildren } from 'react';
import { useMemo, useState } from 'react';
import { ENV } from '@shared/config';
import { CurrencyContext } from '@shared/libs';

export const CurrencyProvider = ({ children }: PropsWithChildren) => {
  const [currencySelect, setCurrencySelect] = useState<string>(ENV.DEFAULT_CERRENCY);

  const currencyContext = useMemo(
    () => ({ currencySelect, setCurrencySelect }),
    [currencySelect, setCurrencySelect],
  );

  return <CurrencyContext.Provider value={currencyContext}>{children}</CurrencyContext.Provider>;
};
