'use client';

import type { PropsWithChildren } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { ModalContext, type ModalContextValue } from '@shared/libs';
import { Modal } from '@shared/ui';

export const ModalProvider = ({ children }: PropsWithChildren) => {
  const [modal, setModal] = useState<ModalContextValue>();

  const handleClose = useCallback(() => {
    setModal(undefined);
  }, []);

  useEffect(() => {
    const html = document.getElementsByTagName('html')?.[0];

    if (modal) {
      const widthBefore = html.offsetWidth;
      html.style.overflow = 'hidden';
      html.style.marginRight = `${html.offsetWidth - widthBefore}px`;
    } else {
      html.style.overflow = 'auto';
      html.style.marginRight = `0px`;
    }
  }, [modal]);

  return (
    <ModalContext.Provider value={setModal}>
      {children}
      {modal && <Modal {...modal.modal} onClose={handleClose} />}
    </ModalContext.Provider>
  );
};
